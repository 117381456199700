<template>
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="0"
      offset-y
      max-height="300"
    >
      <template #activator="{ on, attrs }">
        <v-text-field
          v-model="search"
          :loading="loading"
          :label="word(21)"
          :placeholder="word(106)"
          hide-details
          @input="handleInput"
          @blur="handleManualEntry"
          v-bind="attrs"
          v-on="on"
        />
      </template>

      <v-list v-if="filteredItems.length">
        <v-list-item
          v-for="(item, index) in filteredItems"
          :key="index"
          @click="selectItem(item)"
        >
          <v-list-item-content>
            <v-list-item-title v-html="highlightMatch(item.name)" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list v-else>
        <v-list-item>
          <v-list-item-title>No se encontraron resultados</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { word } from "@/pdf/advanced-quoter/multi-language.js";
import { searchLeadName } from "../../api/lead-api";

export default {
  name: "CustomAutocomplete",
  props: {
    label: {
      type: String,
      default: "Nombre del Lead"
    },
    value: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      search: "",
      menu: false,
      loading: false,
      items: [],
      timer: null,
      delay: 500
    };
  },
  computed: {
    filteredItems() {
      if (!this.search) return [];
      return this.items.filter(item =>
        item.name.toLowerCase().includes(this.search.toLowerCase())
      );
    }
  },
  methods: {
    word(n) {
      return word(n);
    },
    async handleInput(value) {
      if (!value.trim()) return;

      clearTimeout(this.timer);
      this.search = value;
      this.menu = !!value;
      this.loading = true;
      // Debounce the search
      this.timer = setTimeout(async () => {
        try {
          const res = await searchLeadName(value);
          this.items = res.data.lead || [];
        } catch (error) {
          this.items = [];
        } finally {
          this.loading = false;
        }
      }, this.delay);
    },
    selectItem(item) {
      this.search = item.name;
      this.menu = false;
      this.$emit("input", item);
    },
    handleManualEntry() {
      if (this.search.trim()) {
        const newItem = { name: this.search, _id: null };
        this.$emit("input", newItem);
      }
    },
    highlightMatch(text) {
      if (!this.search) return text;
      const regex = new RegExp(`(${this.search})`, "gi");
      return text.replace(regex, "<strong>$1</strong>");
    }
  }
};
</script>

<style scoped>
strong {
  color: #1976d2;
}
</style>
